export class User {
  user_id: number;
  tenant_id: number;
  role_id: number;
  permission_id: number;
  username: string;
  password: any;
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  cover: any ;
  address: string;
  company: string;
  country_id: number;
  timezone_id: number;
  active: number;
  daily_limit: any = 0;
  daily_sent: any = 0;
  monthly_limit: any = 0;
  monthly_sent: any = 0;
  allowed_timeslot: string;
  allowed_days: string;
  confirmPassword: any;
  user_permission: any;
}