import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Http, Response, HttpModule, RequestOptions } from '@angular/http';
import { Tag } from './tag';
import { AppService } from '../../../app/app.service';

import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  aTag: Tag[]= [];
  tag_id: any= null;
  tag: Tag= new Tag;

  constructor(private http: Http, private app_service: AppService) { }

  get_TagList(): Promise<Tag[]> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    return this.http.get(this.app_service.apiUrlTag, options).toPromise()
    .then(response => response.json() as Tag[]).catch(response => this.app_service.handleError(response));
  }

  get_TagData(tag_id): Promise<Tag> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url5 = `${this.app_service.apiUrlTag}/${tag_id}`;
    return this.http.get(url5, options).toPromise()
    .then(response => response.json() as Tag).catch(response => this.app_service.handleError(response));
  }

  add_tag(tag): Promise<Tag> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(tag);
    const addUrl = `${this.app_service.apiUrlTag}`;
    return this.http.post(addUrl, body, options).toPromise().then(response => response.json() as Tag)
    .catch(err => this.app_service.handleError(err));
  }

  delete_Tag(tag_id): Promise<any> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const deleteUrl = `${this.app_service.apiUrlTag}/${tag_id}`;
    return this.http.delete(deleteUrl, options).toPromise().then(response => response.json() as Tag)
   .catch(response => this.app_service.handleError(response));
  }

  update_Tag(tag: Tag): Promise<Tag > {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(tag);
    const updateUrl = `${this.app_service.apiUrlTag}/${tag.tag_id}`;
    return this.http.put(updateUrl, body, options).toPromise().then(response => response.json() as Tag)
    .catch(response => this.app_service.handleError(response));
  }

}
